<template>
  <router-view name="product" v-bind:key="$route.path"></router-view>
</template>

<script>
export default {
  name: "Product",
};
</script>

<style lang="scss">
/* Ürün detay */
.uploaded-card,
.product-image-card {
  border-radius: 0.25rem;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.product-image-card.featured {
  border-top-left-radius: 0;
  border: 2px solid #2ed573;
  background-color: #2ed5740e;
  margin-top: 10px;
  box-shadow: 1px 3px 5px rgba(128, 128, 128, 0.295);
}

.product-image-card:not(:last-child) {
  margin-bottom: 1rem;
}

.product-image-card.featured::before {
  content: "Ürün Ana Görseli";

  font-size: 12px;
  font-weight: 700;

  position: absolute;
  padding: 2px 4px 0px 4px;
  left: -2px;
  top: -20px;

  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  color: #ffffff;
  background-color: #2ed573;
}

.uploaded-media,
.product-media {
  align-items: center;
}
.accordion-overflow img,
.variant-product-img,
.product-media img,
.uploaded-media img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  background-color: #fff;
  border: 2px solid #2ed573;
  border-radius: 0.25rem !important;
}
.accordion-overflow img,
.variant-product-img {
  width: 50px;
  height: 50px;
}

.uploaded-media .media-body span,
.product-media .media-body span {
  font-size: 14px;
  display: block;
}

.category-list-group {
  max-height: 250px;
  overflow: auto;
}

.category-list-group li {
  transition: 0.4s all;
}

.category-list-group li:hover {
  background-color: #ced6e0;
}

.trash-area {
  display: none;
}

.uploaded-media:hover .trash-area,
.product-media:hover .trash-area {
  display: block;
}

.feature-group-card {
  border-radius: 0.25rem;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-top: 2rem;
  transition: 0.4s all;
}

.feature-group-card .feature-title {
  text-transform: uppercase;
  background-color: #ffffff;
  color: #6b6b6b;
  font-weight: 700;

  padding: 0 15px;
  position: absolute;
  top: -10px;
  left: 10px;
}

.feature-group-card:hover {
  box-shadow: 1px 3px 5px rgba(128, 128, 128, 0.295);
}

.feature-group-card:hover .feature-remove {
  display: block;
}

.feature-group-card .feature-remove {
  color: #ff4757;
  text-decoration: none;
  background-color: #ffffff;

  padding: 0 15px;
  position: absolute;
  top: -10px;
  right: 10px;

  display: none;
}

.feature-group-card .feature-remove:hover {
  color: #bb2c38;
}

.accordion-overflow {
  max-height: 250px;
  overflow: auto;
}

/* Ürün detay */
</style>
